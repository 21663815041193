body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.rating-span{
  margin:0.1rem;
}

.rating svg{
  color:#f8e825;
}
.rating-text{
  font-size: 0.8rem;
  font-weight: 600;
  padding-left:0.5rem;
}
.product-title{
  height: 2.5em;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

a{
  text-decoration: none;
  color:inherit;
}

.d-flex{
  display: flex ;
  flex-direction: row ;
  justify-content: space-evenly ;
  align-items: center ;
}
.carousel-caption{
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;  
  width: 100%;
}
.brand-name{
  position: relative;
}
